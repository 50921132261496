import { Sky, PerspectiveCamera, ContactShadows, Environment, Stage, MeshReflectorMaterial } from '@react-three/drei'

export default function Space1() {

    return(
        <>  
            {/* <color attach="background" args={['#101010']} /> */}
            <fog attach="fog" args={['#101010', 10, 20]} />
            <Stage intensity={1} contactShadow={false} shadowBias={-0.0015}/>
            <mesh rotation={[-Math.PI / 2, 0, 0]}>
                <planeGeometry args={[100, 100]} />
                <MeshReflectorMaterial
                    mirror= {0}
                    blur={[300, 100]}
                    resolution={2048}
                    mixBlur={1}
                    mixStrength={40}
                    roughness={1}
                    depthScale={1.2}
                    minDepthThreshold={0.4}
                    maxDepthThreshold={1.4}
                    color="#101010"
                    metalness={0.5}
                />
            </mesh>
        </>
    )
}