/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ name, ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/betas/'+ name +'.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <primitive object={nodes.mixamorigHips} />
        <skinnedMesh geometry={nodes.Body.geometry} material={materials.body_material} skeleton={nodes.Body.skeleton} />
      </group>
    </group>
  )
}

//useGLTF.preload('/betas/'+ name +'.glb')