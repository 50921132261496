/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useControls } from '../utils/useControls'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/betas/beta0008.glb')
  const { actions } = useAnimations(animations, group)
  const controls = useControls()
  const [moving, setMoving] = useState(false)
  const velocity = 13
  useEffect (() => {
    if (moving){
      actions['Running'].stop();
      actions['Idle'].stop();
      actions['Running'].fadeIn(.5).play();
    } else {
      actions['Running'].fadeOut(.5);
      actions['Idle'].fadeIn(.2).play();
    }
  }, [actions, moving]);
  useFrame((state, delta) => {
    const { forward, backward, left, right, brake, reset } = controls.current

    if (forward && group.current.position.z > -49) {
      group.current.position.z -= velocity*delta;
      if (left) {
        group.current.rotation.y = -3*Math.PI/4;
      } else if (right){
        group.current.rotation.y = 3*Math.PI/4;
      } else {
        group.current.rotation.y = Math.PI
      }
    }
    if (backward && group.current.position.z < 49){
      group.current.position.z += velocity*delta;
      if (left) {
        group.current.rotation.y = -Math.PI/4;
      } else if (right){
        group.current.rotation.y = Math.PI/4;
      } else {
        group.current.rotation.y = 0
      }
    } 
    if (left && group.current.position.x > -49){
      group.current.position.x -= velocity*delta;
      if (!forward && !backward) group.current.rotation.y = -Math.PI/2
    } 
    if (right && group.current.position.x < 49){
      group.current.position.x += velocity*delta;
      if (!forward && !backward) group.current.rotation.y = Math.PI/2
    } 

    if (forward || backward || left || right){
      setMoving(true);
    } else {
      setMoving(false);
    }

    const defaultCamera = state.camera
    defaultCamera.position.z = group.current.position.z + 10;
    defaultCamera.position.x = group.current.position.x;
    defaultCamera.position.y = 7;
    defaultCamera.rotation.x = -Math.PI/10
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <primitive object={nodes.mixamorigHips} />
        <skinnedMesh
          geometry={nodes.Body.geometry}
          material={materials.body_material}
          skeleton={nodes.Body.skeleton}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/betas/beta0008.glb')
