import { Suspense, useState, useRef } from "react";
import { ChakraProvider, useDisclosure, Button, Flex} from "@chakra-ui/react";
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Sky, PerspectiveCamera, Plane, ContactShadows } from '@react-three/drei'
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette} from "@react-three/postprocessing";
import theme from "./theme";
import "@fontsource/inter";
import TestingGround from "./spaces/TestingGround";
import Space1 from "./spaces/Space1"

import { useEthers } from "@usedapp/core";

import Layout from "./components/Layout";
import UI from "./components/UI";

import Player from "./objects/Player.js"
import Beta from "./objects/Beta.js"


function App() {
  const [action, setAction] = useState("idle");
  const { account } = useEthers();
  return (
    <ChakraProvider theme={theme}>
        <Layout> 
          <UI setAction={ setAction }/>
          <div style={{ position: "absolute", width: window.innerWidth, height: window.innerHeight }}>
            <Suspense fallback={<div>Loading... </div>}>
              <Canvas>
                {/* <TestingGround/> */}
                <Space1/>
                <Beta name = "beta0000" position={[0,0,-4]}/>
                <Beta name = "beta0001" position={[3,0,-4]}/>
                <Beta name = "beta0002" position={[6,0,-4]}/>
                <Beta name = "beta0003" position={[9,0,-4]}/>
                <Beta name = "beta0004" position={[12,0,-4]}/>
                <Beta name = "beta0005" position={[-3,0,-4]}/>
                <Beta name = "beta0006" position={[-6,0,-4]}/>
                <Beta name = "beta0007" position={[-9,0,-4]}/>
                <Beta name = "beta0009" position={[-12,0,-4]}/>
                {account && <Player/>}
              </Canvas>
            </Suspense>
          </div>
        </Layout>
    </ChakraProvider>
  );
}

export default App;
