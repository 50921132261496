import { useDisclosure, Button } from "@chakra-ui/react";
import ConnectButton from "./elements/ConnectButton";
import AccountModal from "./elements/AccountModal";
import { useEthers } from "@usedapp/core";


export default function UI( { setAction }:any ) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { account } = useEthers();
    return (
        <>
            <div style={{ top:"2vh", right:"2vh", position: "absolute",
                            zIndex: 1, overflow:"hidden" }}>
                <ConnectButton handleOpenModal={onOpen} />
                <AccountModal isOpen={isOpen} onClose={onClose} />
            </div>
            {/* {account &&
                <Button style={{top:"10vh", zIndex: 1,  overflow:"hidden" }}
                colorScheme="teal" 
                size="md"
                onClick={() => setAction("open")}>
                Mint
            </Button>} */}

        </>
    )
}

