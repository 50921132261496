import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

type Props = {
  children?: ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      //justifyContent="center"
      h="100vh"
      //bg="gray.800"
      //bgGradient='linear(to-t, gray.900, gray.800, gray.900)'
    >
      {children}
    </Flex>
  );
}
